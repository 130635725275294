import { defineStore } from 'pinia'
import _ from 'lodash';

export const useCompaniesStore = defineStore('companies', {
  state: function() {
    return {
      companies: null,
      companiesInterval: false,
      loading: false,
    };
  },
  actions: {
    fetchCompanies: _.throttle(function() {
      if (this.loading) return;
      this.loading = true;
//      if (!this.companiesInterval) {
//        this.companiesInterval = setInterval(() => this.fetchCompanies(), 300000);
//      }
      window.axios.get('/api/smart/companies').then(response => {
        this.companies = response.data.data;
      }).catch((e) => {
        console.log("MIG- Companies Not possible");
      }).finally(() => {
        this.loading = false;
      });
    }),
    loadCompanies() {
      this.fetchCompanies();
    }
  },
});
